import { createContext } from 'react';

export const defaultFlags = {
    isVideo: true,
    isShowFooter: true
};

export const SetFlagsContext = createContext({
    ...defaultFlags,
    set: () => {},
});

export default SetFlagsContext;
