import { useMemo } from 'react';

export function useContainerStyle(props) {
    const {
        screenWidth,
        screenHeight,
        BG_WIDTH,
        BG_HEIGHT,
    } = props;

    return useMemo(() => {
        if ((screenWidth / screenHeight) < (BG_WIDTH / BG_HEIGHT)) {
            const marginHeight = (screenHeight - (screenWidth * BG_HEIGHT) / BG_WIDTH) / 2;
            const marginStr = `${marginHeight}px`;
            return {
                marginTop: marginStr,
                marginBottom: marginStr,
            };
        }

        const marginWidth =
            (screenWidth - (BG_WIDTH * screenHeight) / BG_HEIGHT) / 2;
        const marginStr = `${marginWidth}px`;
        return {
            marginLeft: marginStr,
            marginRight: marginStr,
        };
    }, [
        screenWidth,
        screenHeight,
        BG_WIDTH,
        BG_HEIGHT,
    ]);
}
