import { useState, useCallback, useMemo } from 'react';
import ReactPlayer from 'react-player/lazy'
import LogoImg from '../image/artly-white-logo.png';
import SNSImg from '../image/sns.png';
import QRCodeImg from '../image/artly-qr-code-frame.png';
import PromotionImg from '../image/free-toppings.png';

import {
    LANDSCAPE_VIDEO_URL,
    PORTRAIT_VIDEO_URL,
    FALLBACK_LANDSCAPE_VIDEO_URL,
    FALLBACK_PORTRAIT_VIDEO_URL,
    VIDEO_BACKGROUND_COLOR,
    LANDSCAPE_VIDEO_ASPECT_RATIO,
    PORTRAIT_VIDEO_ASPECT_RATIO,
} from '../constant';

/*
 Display the promotion image only at Hill7 location. 
 This is a magic number check which is not technically great. 
 But we'll not implement it in an elegant way for now.
*/
function getRightBottomImageUrl(storeId) {
    if (storeId === "20") {
        return PromotionImg;
    } else {
        return QRCodeImg;
    }
}

function Overlays(props) {
    const { storeId, landscapeFlag, screenWidth, screenHeight } = props;

    const style = useMemo(() => {
        const ratio = landscapeFlag ? LANDSCAPE_VIDEO_ASPECT_RATIO : PORTRAIT_VIDEO_ASPECT_RATIO;
        if ((screenWidth / screenHeight) < ratio) { // full-width
            const value = (screenHeight - (screenWidth / ratio)) / 2;
            return {
                top: `${value}px`,
                left: 0,
                height: `${screenHeight - value * 2}px`,
                width: '100vw',
            };
        }
        const value = (screenWidth - (screenHeight * ratio)) / 2;
        return {
            left: `${value}px`,
            top: 0,
            width: `${screenWidth - value * 2}px`,
            height: '100vh',
        };
    }, [screenWidth, screenHeight, landscapeFlag]);

    const extraClassName = landscapeFlag ? 'horz' : 'vert';
    return (
        <div className={`overlays ${extraClassName}`} style={style}>
            <img className="logo" src={LogoImg} alt="artly" />
            <img className="sns" src={SNSImg} alt="sns" />
            <img className="qrcode" src={getRightBottomImageUrl(storeId)} alt="qrcode" />
        </div>
    );
}

export default function VideoPlayer(props) {
    const { storeId, landscapeFlag, screenWidth, screenHeight } = props;
    const [isFallback, setIsFallback] = useState(false);

    const onError = useCallback(() => {
        setIsFallback(true);
    }, [setIsFallback]);

    const deviceVideoUrl = landscapeFlag
        ? LANDSCAPE_VIDEO_URL
        : PORTRAIT_VIDEO_URL;
    const fallbackVideoUrl = landscapeFlag
        ? FALLBACK_LANDSCAPE_VIDEO_URL
        : FALLBACK_PORTRAIT_VIDEO_URL;
    const videoUrl = isFallback ? fallbackVideoUrl : deviceVideoUrl;

    return (
        <>
            <Overlays storeId={storeId} landscapeFlag={landscapeFlag} screenWidth={screenWidth} screenHeight={screenHeight} />
            <ReactPlayer
                url={videoUrl}
                width="100vw"
                height="100vh"
                style={{ backgroundColor: VIDEO_BACKGROUND_COLOR }}
                loop
                muted
                playing
                onError={onError}
            />
        </>
    );

}
