import "react-responsive-carousel/lib/styles/carousel.min.css"

import { useState, useMemo, useEffect } from 'react'
import {
    useLocation,
    useRouteMatch,
} from 'react-router-dom'
import useReportDeviceState from './reportDeviceState/useReportDeviceState'

import Landscape from './components/landscape'
import Portrait from './components/portrait'
import VideoPlayer from './components/VideoPlayer';

import { defaultFlags, SetFlagsContext } from './components/flagContext';

import './App.css'

function getScreenInfo () {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    // to fix menu bar / bottom bar height problem see https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
    document.documentElement.style.setProperty('--vh', `${screenHeight / 100}px`);

    const landscapeFlag = (screenWidth / screenHeight) > 1;
    return {
        screenWidth,
        screenHeight,
        landscapeFlag,
    };
}


function App () {
    const [screenInfo, setScreenInfo] = useState(getScreenInfo())
    const [flags, setFlags] = useState(defaultFlags);
    const matchMenu = useRouteMatch('/menu');

    const routerLocation = useLocation();
    const [storeId, deviceSerialNumber] = useMemo(() => {
        const queryParams = new URLSearchParams(routerLocation.search)
        const storeId = queryParams.get('storeId') || 0;
        const deviceSerialNumber = queryParams.get('deviceSerialNumber') || '';

        return [storeId, deviceSerialNumber];
    }, [routerLocation]);

    // report device state periodically
    useReportDeviceState({ storeId, deviceSerialNumber });

    // onResize to set screenInfo
    useEffect(() => {
        const onResize = () => {
            setScreenInfo(getScreenInfo())
        };
        onResize();
        window.addEventListener('resize', onResize);

        // recalculate the size in delay in case we got a wrong size at initial stage
        const timeout = setTimeout(onResize, 1000);

        return () => {
            window.removeEventListener('resize', onResize);
            clearTimeout(timeout);
        };
    }, []);

    const RenderComponent = screenInfo.landscapeFlag ? Landscape : Portrait;
    return (
        <SetFlagsContext.Provider value={{ ...flags, set: setFlags }}>
            {(flags.isVideo && !matchMenu) && <VideoPlayer {...screenInfo} storeId={storeId} />}
            <div className="app">
                <RenderComponent storeId={storeId} {...screenInfo} matchMenu={matchMenu} />
            </div>
        </SetFlagsContext.Provider>
    );
}

export default App
