import { useContainerStyle } from '../hooks';
import PromotionMessage from '../promotionMessage'
import Waitings from '../waitings'
import MenuPage from '../menu/MenuPage';

const BG_WIDTH = 1136;
const BG_HEIGHT = 1781;

function Portrait (props) {
    const { screenWidth, screenHeight, landscapeFlag, storeId, matchMenu } = props;
    const clsName = (screenWidth / screenHeight) < (BG_WIDTH / BG_HEIGHT) ? 'full-width' : 'full-height';
    const style = useContainerStyle({
        screenWidth,
        screenHeight,
        BG_WIDTH: matchMenu ? BG_WIDTH : 2158,
        BG_HEIGHT: matchMenu ? BG_HEIGHT : 3560,
    });
    const menuCls = matchMenu ? 'menu' : '';
    return (
        <div
            className={`portrait ${menuCls} ${clsName}`}
            style={style}
        >
            {matchMenu ? (
                <>
                    <div className="blue-circle" />
                    <div className="pink-circle" />
                    <MenuPage storeId={storeId} landscapeFlag={landscapeFlag} />
                </>
            ) : (
                <>
                    <div className="pink-circle" />
                    <Waitings storeId={storeId} landscapeFlag={landscapeFlag} />
                    <PromotionMessage storeId={storeId} />
                </>
            )}
        </div>
    );
}

export default Portrait
