import { useMemo } from 'react'; 
const PORTRAIT_PAGE_SIZE = 10;
const LANDSCAPE_PAGE_SIZE = 10;
const PORTRAIT_SCROLL_NAME = 'portrait-scroll';
const LANDSCAPE_SCROLL_NAME = 'landscape-scroll';

function OrderItem(props) {
    const { orderItem } = props;
    // const doneName = ['DONE', 'PREPARING' ].includes(orderItem.itemStatus) ? 'done' : '';
    const doneName = ('DONE' === orderItem.itemStatus) ? 'done' : '';
    const preparingName = ('PREPARING' === orderItem.itemStatus) ? 'preparing' : '';
    const className=`order ${doneName} ${preparingName}`;
    const { scheduleInfo } = orderItem;
    const est = scheduleInfo?.scheduleType === 'SCHEDULED'
        ? (
            <>
                <span className="icon icon-waiting" />
                <span>{`${(scheduleInfo.scheduledTimeInterval.endTime.hour % 12) || 12}:${`${scheduleInfo?.scheduledTimeInterval.endTime.minute}`.padStart(2, '0')}${scheduleInfo.scheduledTimeInterval.endTime.hour < 12 ? 'AM' : 'PM'}`}</span>
            </>
        )
        : `${orderItem.waitingInfo.estimatedMinWaitingTime / 1000 / 60 | 0} mins`;
    // {isShowBarista && <p className="barista">{order.robotOrderItemInfo?.robotName || 'Javris'}</p>}
    return (
        <div className={className}>
            <p className="name">{orderItem.displayCustomerName}</p>
            <p className="product cap">
                <span className="status-icon" />
                <span>{orderItem.displayName}</span>
            </p>
            <p className="est right">{doneName ? 'DONE' : est}</p>
        </div>
    );
}

function RobotOrderItems(props) {
    const { name = '', items = [], manual = false } = props;
    return (
        <div className="robot">
            <div className="robot-info">
                <div className={['icon', manual ? 'icon-manual' : 'icon-robot'].join(' ')} />
                <span className="robot-name">{name.toUpperCase()}</span>
            </div>
            <div className="robot-order-items">
                {items.map((item) => (
                    <OrderItem
                        key={item.itemId}
                        orderItem={item}
                    />
                ))}
            </div>
        </div>
    );

}

export default function WaitingList(props) {
    const { orderItems, landscapeFlag } = props;

    const { robots, manualItems, waitingItems } = useMemo(() => {
        const robots = []; // { name: '', items: [] }
        const manualItems = [];
        const waitingItems = [];
        orderItems.forEach((item) => {
            if (item.madeByRobot) {
                if (item.robotOrderItemInfo) {
                    let robotInfo = robots.find(v => v.name === item.robotOrderItemInfo.robotName);
                    if (!robotInfo) {
                        // initialize robot info
                        robotInfo = {
                            name: item.robotOrderItemInfo.robotName,
                            items: []
                        };
                        robots.push(robotInfo);
                    }
                    robotInfo.items.push(item);
                    return;
                } else {
                    waitingItems.push(item);
                }
            } else {
                manualItems.push(item);
            }
        });
        return { robots, manualItems, waitingItems };
    }, [orderItems]);

    // num of items + WAITING title line (1) + robot name line
    const len = orderItems.length + 1 + robots.length;
    const pageSize = landscapeFlag ? LANDSCAPE_PAGE_SIZE : PORTRAIT_PAGE_SIZE;
    const animationName = landscapeFlag ? LANDSCAPE_SCROLL_NAME : PORTRAIT_SCROLL_NAME;
    const duration = len <= pageSize ? 0 : 5000 + (len - pageSize) * 1000;
    const scrollStyle = {
        animationName,
        animationDuration: `${duration}ms`
    };

    return (
        <div className="waiting">
            <div className="wrapper">
                <div className="content">
                    <div className="content-scroll" style={scrollStyle}>
                        {robots.map(robot => <RobotOrderItems key={robot.name} {...robot} />)}
                        {manualItems.length > 0 && (
                            <RobotOrderItems key="manual" name="Artly Ambassador" items={manualItems} manual />
                        )}
                        <div className="robot-info waiting-list-title">
                            <div className="robot-icon waiting-icon" />
                            <span className="robot-name">Waiting</span>
                        </div>
                        <div className="order-items">
                            {waitingItems.map((item) => (
                                <OrderItem
                                    key={item.itemId}
                                    orderItem={item}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
