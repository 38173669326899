export const BASE_URL =
    process.env.REACT_APP_BASE_URL || "https://api.bluehill.coffee";

export const LANDSCAPE_VIDEO_URL =
    process.env.REACT_APP_LANDSCAPE_VIDEO_URL ||
    "http://127.0.0.1/video/video4tv.mp4";
export const PORTRAIT_VIDEO_URL =
    process.env.REACT_APP_PORTRAIT_VIDEO_URL ||
    "http://127.0.0.1/video/video4tv-vertical.mp4";

export const FALLBACK_LANDSCAPE_VIDEO_URL =
    process.env.REACT_APP_FALLBACK_LANDSCAPE_VIDEO_URL ||
    "https://youtu.be/cZ0vrbZy3W8?si=LR_-g17CCbCBdwdn";
export const FALLBACK_PORTRAIT_VIDEO_URL =
    process.env.REACT_APP_FALLBACK_PORTRAIT_VIDEO_URL ||
    "https://youtu.be/bpSDGpaN0XI?si=bnFsnAbvhRosw8xY";
export const VIDEO_BACKGROUND_COLOR =
    process.env.REACT_APP_VIDEO_BACKGROUND_COLOR || "#000";

export const REPORT_DEVICE_INTERVAL = process.env
    .REACT_APP_REPORT_DEVICE_INTERVAL
    ? parseInt(process.env.REACT_APP_REPORT_DEVICE_INTERVAL, 10)
    : 30 * 1000;
export const FETCH_PROMOTION_INTERVAL = process.env
    .REACT_APP_FETCH_PROMOTION_INTERVAL
    ? parseInt(process.env.REACT_APP_FETCH_PROMOTION_INTERVAL, 10)
    : 10 * 60 * 1000;
export const FETCH_WAITING_INTERVAL = process.env
    .REACT_APP_FETCH_WAITING_INTERVAL
    ? parseInt(process.env.REACT_APP_FETCH_WAITING_INTERVAL, 10)
    : 10 * 1000;
export const FETCH_ROBOT_INTERVAL = process.env
    .REACT_APP_FETCH_ROBOT_INTERVAL
    ? parseInt(process.env.REACT_APP_FETCH_ROBOT_INTERVAL, 10)
    : 30 * 60 * 1000;
export const FETCH_PRODUCT_INTERVAL = process.env
    .REACT_APP_FETCH_PRODUCT_INTERVAL
    ? parseInt(process.env.REACT_APP_FETCH_PRODUCT_INTERVAL, 10)
    : 30 * 60 * 1000;

export const PROMOTION_SWIPE_INTERVAL = process.env
    .REACT_APP_PROMOTION_SWIPE_INTERVAL
    ? parseInt(process.env.REACT_APP_PROMOTION_SWIPE_INTERVAL, 10)
    : 20000;

// ratio = width / height
export const LANDSCAPE_VIDEO_ASPECT_RATIO = process.env.REACT_APP_LANDSCAPE_VIDEO_ASPECT_RATIO
    ? parseFloat(process.env.REACT_APP_LANDSCAPE_VIDEO_ASPECT_RATIO)
    : 1.777777778;

export const PORTRAIT_VIDEO_ASPECT_RATIO = process.env.REACT_APP_LANDSCAPE_VIDEO_ASPECT_RATIO
    ? parseFloat(process.env.REACT_APP_LANDSCAPE_VIDEO_ASPECT_RATIO)
    : 0.5625;


export const HOT_DRINK = 'tvMenuGroup.hotDrink';
export const ICED_DRINK = 'tvMenuGroup.icedDrink';
export const FOOD = 'tvMenuGroup.food';
export const COFFEE_BEAN = 'tvMenuGroup.coffeeBean';

export const GROUPS = [HOT_DRINK, ICED_DRINK, FOOD, COFFEE_BEAN];
