import { useEffect, useState } from 'react';
import { BASE_URL, FETCH_PRODUCT_INTERVAL, GROUPS } from '../../constant';
// import mockData from './mockProduct';


function transformProducts(products = []) {
    const data = {};
    // initial data with empty list group
    GROUPS.forEach(v => data[v] = []);

    products.forEach((item) => {
        if (item.enabled) {
            const groups = item.groups || [];
            groups.forEach(group => {
                if (group.groupType === 'TV_MENU') {
                    data[group.groupKey].push(item);
                }
            });
        }
    });
    return data;
}

export default function useGroupedProduct(storeId = 0) {
    const [data, setData] = useState(false);
    useEffect(() => {
        const fetchData = () => {
            fetch(`${BASE_URL}/v1/products?storeId=${storeId}&enabled=true`)
                .then(result => result.json())
                .then(result => {
                    setData(transformProducts(result.products));
                    // setData(transformProducts(mockData.products));
                })
                .catch(() => {
                    // TODO
                });
        };
        fetchData();
        const interval = setInterval(fetchData, FETCH_PRODUCT_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [storeId]);

    return data;
}
