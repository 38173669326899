import { useEffect, useState, useCallback } from "react"
import { Carousel } from 'react-responsive-carousel'

import { BASE_URL, FETCH_PROMOTION_INTERVAL, PROMOTION_SWIPE_INTERVAL } from '../constant';


export default function PromotionMessage(props) {
    const { storeId } = props;

    const [promotionCampaigns, setPromotionCampaigns] = useState([]);

    const fetchPromotionCampaigns = useCallback(() => {
        fetch(`${BASE_URL}/v1/promotion_campaigns?storeId=${storeId}`)
            .then(result => result.json())
            .then(result => setPromotionCampaigns(result.promotionCampaigns))
            .catch(() => {
                // TODO
            });
    }, [storeId, setPromotionCampaigns]);

    useEffect(() => {
        fetchPromotionCampaigns()
        const interval = setInterval(() => {
            fetchPromotionCampaigns()
        }, FETCH_PROMOTION_INTERVAL)
        return () => {
            clearInterval(interval);
        };
    }, [fetchPromotionCampaigns]);

    if (promotionCampaigns.length <= 0) {
        return null;
    }

    return (
        <div className="promotion">
            <Carousel
                interval={PROMOTION_SWIPE_INTERVAL}
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                swipeable={true}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
            >
                {promotionCampaigns
                    .filter(
                        (promotionCampaign) => promotionCampaign.displayedNow
                    )
                    .map((promotionCampaign) => {
                        return (
                            <div
                                className="carousel-wrapper"
                                key={promotionCampaign.campaignId}
                            >
                                <p className="promotion-message-title">
                                    {promotionCampaign.campaignName}
                                </p>
                                <p className="promotion-message-text">
                                    {promotionCampaign.shortDescription}
                                </p>
                            </div>
                        );
                    })}
            </Carousel>
        </div>
    );
}
