import { useEffect, useCallback, useMemo } from 'react';

import { BASE_URL, REPORT_DEVICE_INTERVAL } from '../constant';


// A hook to report device state periodically
export default function useReportDeviceState(props) {
    const { storeId, deviceSerialNumber } = props;

    const reportOption = useMemo(() => ({
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ storeId })
    }), [storeId]);
    const reportState = useCallback(() => {
        if (deviceSerialNumber) {
            fetch(`${BASE_URL}/v1/tv_computers_state/${deviceSerialNumber}`, reportOption);
        }
    }, [reportOption, deviceSerialNumber]);

    useEffect(() => {
        reportState();
        const interval = setInterval(reportState, REPORT_DEVICE_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [reportState]);
}

