import { useEffect, useCallback, useState, useContext } from 'react';
import { SetFlagsContext } from './flagContext';
import WaitingList from './waitingList';
import { BASE_URL, FETCH_WAITING_INTERVAL } from '../constant';
// import mockWaiting from './mockWaiting';

function TotalTime(props) {
    const { value } = props;

    return (
        <div className="waiting-total">
            Current waiting time is {value} mins
        </div>
    );
}

export default function Waitings(props) {
    const { storeId, landscapeFlag } = props;
    const [minWaitingTime, setMinWaitingTime] = useState(0);
    const [orderItems, setOrderItems] = useState([]);
    const [isWaitingTimeFetched, setIsWaitingTimeFetched] = useState(false);
    const { set: setFlags } = useContext(SetFlagsContext);

    useEffect(() => {
        const len = orderItems.length;
        setFlags({
            isVideo: !isWaitingTimeFetched || !len,
            isShowFooter: len < 7,
        });
    }, [setFlags, isWaitingTimeFetched, orderItems])

    const fetchWaitingTime = useCallback(() => {
        fetch(`${BASE_URL}/v1/stores/${storeId}/waiting_info`)
            .then(result => result.json())
            .then(result => {
                setMinWaitingTime(result.estimatedMinWaitingTime / 1000 / 60 | 0);
                if (!isWaitingTimeFetched) {
                    setIsWaitingTimeFetched(true);
                }
            })
            .catch(() => {
                // TODO
            });
    }, [storeId, setMinWaitingTime, isWaitingTimeFetched, setIsWaitingTimeFetched]);

    const fetchWaitingItems = useCallback(() => {
        fetch(`${BASE_URL}/v1/orders/~/items?storeId=${storeId}`)
            .then(result => result.json())
            .then(result => setOrderItems(result.orderItems ?? []))
            .catch(() => {
                // TODO
            });
    }, [storeId, setOrderItems]);

    useEffect(() => {
        fetchWaitingTime()
        fetchWaitingItems()
        const interval = setInterval(() => {
            fetchWaitingTime()
            fetchWaitingItems()
        }, FETCH_WAITING_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [storeId, fetchWaitingTime, fetchWaitingItems]);


    return (
        <>
            <TotalTime value={minWaitingTime} />
            <WaitingList
                orderItems={orderItems}
                landscapeFlag={landscapeFlag}
            />
        </>
    );

};
