import useGroupedProduct from './useGroupedProduct';
import { HOT_DRINK, ICED_DRINK, FOOD, COFFEE_BEAN } from '../../constant';

import './menu.css';


function MenuItem(props) {
    const { item } = props;
    const price = item.discountedBasePrice || item.basePrice;
    const priceCls = `price ${item.discountedBasePrice ? 'has-discount' : ''}`;
    return (
        <div className="menu-item">
            <div className="product-name">{item.shortProductName}</div>
            <div className={priceCls}>${(price / 100).toFixed(2)}</div>
            {item.discountedBasePrice != null ? (
                <div className="discount">${item.basePrice ? (item.basePrice / 100).toFixed(2) : '0'}</div>
            ) : null}
        </div>
    );
}

export default function MenuPage(props) {
    const { storeId } = props;
    const data = useGroupedProduct(storeId);
    if (!data) {
        return null;
    }
    const hotDrinks = data[HOT_DRINK];
    const icedDrinks = data[ICED_DRINK];
    const foods = data[FOOD];
    const coffeeBeans = data[COFFEE_BEAN];
    return (
        <div className="menu-content">
            <div className="menu-card hot1">
                {hotDrinks.slice(0, 6).map(v => <MenuItem key={v.id} item={v} />)}
            </div>
            <div className="menu-card hot2">
                {hotDrinks.slice(6, 11).map(v => <MenuItem key={v.id} item={v} />)}
            </div>
            <div className="menu-card cold1">
                {icedDrinks.slice(0, 6).map(v => <MenuItem key={v.id} item={v} />)}
            </div>
            <div className="menu-card cold2">
                {icedDrinks.slice(6, 14).map(v => <MenuItem key={v.id} item={v} />)}
            </div>
            <div className="menu-card food1">
                {foods.slice(0, 5).map(v => <MenuItem key={v.id} item={v} />)}
            </div>
            <div className="menu-card food2">
                {foods.slice(5, 7).map(v => <MenuItem key={v.id} item={v} />)}
            </div>
            <div className="menu-card bean">
                {coffeeBeans.slice(0, 1).map(v => <MenuItem key={v.id} item={v} />)}
            </div>
        </div>
    );
}
